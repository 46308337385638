.bg_home {
  height: 100vh;
  background-image: url("../img/bg.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.logo {
  height: 202px;
  margin-top: 100px;
  background-image: url("../img/logo.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.heading {
  margin-top: 30px;
  margin-bottom: 0px;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 31px;
  text-align: center;
  text-transform: uppercase;
}

.form_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form_bg {
  width: 693px;
  height: 400px;
  background-color: transparent;
}

.mensaje_title {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}

.mensaje_green_box {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 193px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #196534;
  box-shadow: 0 1px 3px 0 #504e4e;
}

.form_white_bg {
  height: 100%;
  margin-top: -10px;
  margin-left: 10px;
  padding-right: 0px;
  background-color: #fff;
}

.form_block {
  height: 100%;
  padding-top: 30px;
  padding-right: 0px;
  padding-left: 0px;
  box-shadow: 0 1px 10px 0 #585757;
}

.enviat_btn_container {
  width: 203px;
  height: 50px;
  margin-top: 40px;
  margin-left: -14px;
}

.registrate_title {
  margin-top: 50px;
  font-family: Montserrat, sans-serif;
  color: #196534;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.text-block {
  font-family: Montserrat, sans-serif;
  color: #196534;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.new_website_text {
  margin-top: 20px;
}

.new_website_label {
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.form {
  padding-right: 0px;
  padding-left: 0px;
}

.form_padding {
  padding-right: 15px;
  padding-left: 15px;
}

.age_section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #186735;
}

.div-block {
  width: auto;
  min-height: 300px;
  min-width: 400px;
  border: 1px solid #fff;
}

.h1_age {
  font-family: Montserrat, sans-serif;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  line-height: 30px;
}

.age_btn_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  text-align: center;
  padding: 10px;
}

.btn_div {
  margin-top: 15px;
  padding-right: 40px;
  padding-left: 40px;
  border: 1px none #000;
  border-radius: 8px;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  color: #186735;
  font-weight: 700;
}

.age_container {
  padding: 40px;
  border: 1px solid #fff;
}

@media screen and (max-width: 991px) {
  .bg_home {
    height: auto;
  }
  .heading {
    font-size: 31px;
    line-height: 20px;
  }

  .form_block {
    padding-right: 20px;
    padding-left: 20px;
  }

  .text-field {
    border-bottom: 1px solid grey;
  }

  .text-field-2 {
    border-bottom: 1px solid grey;
  }

  .text-field-3 {
    border-bottom: 1px solid grey;
  }

  .enviat_btn_container {
    width: 203px;
    height: 50px;
    margin-top: -80px;
  }
}

@media screen and (max-width: 767px) {
  .bg_home {
    height: 100vh;
  }

  .logo {
    height: 145px;
    margin-top: 50px;
    background-size: contain;
  }

  .heading {
    font-size: 21px;
    line-height: 20px;
  }

  .form_white_bg {
    margin-top: -10px;
    margin-left: 0px;
  }

  .form_block {
    padding-right: 0px;
    padding-left: 0px;
  }

  .enviat_btn_container {
    margin-top: 30px;
  }
}

@media screen and (max-width: 479px) {
  .logo {
    height: 85px;
  }

  .heading {
    line-height: 24px;
    text-align: center;
  }

  .form_block {
    padding-right: 0px;
    padding-left: 0px;
  }

  .enviat_btn_container {
    margin-top: 30px;
  }

  .registrate_title {
    font-size: 16px;
  }
}

.margin-left {
  margin-left: 5px;
}

.margin-right {
  margin-right: 5px;
}

.pointer {
  cursor: pointer;
}

.error {
  border: red solid 1px;
}
